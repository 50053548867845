import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

import {
	// AppAside,
	AppBreadcrumb,
	// AppFooter,
	AppHeader,
	AppSidebar,
	AppSidebarFooter,
	AppSidebarForm,
	AppSidebarHeader,
	// AppSidebarMinimizer,
	AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import navigation from '../../_nav';
// routes config
import routes from '../../routes';
// import DefaultAside from './DefaultAside';
// import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';

import Loading from '../../components/Loading';

const getNavigationItems = userType => {
	const items = navigation.items.filter(navI => {
		return !navI.visibleBy || navI.visibleBy.includes(userType);
	});

	return { items };
};

const ME_QUERY = gql`
	query UserMe {
		me {
			username
			email
			type
		}
	}
`;

const DefaultLayout = props => {
	const { loading, error, data } = useQuery(ME_QUERY, { cache: false });

	if (loading) return <Loading text='Entrando al sistema' />;
	if (error) return `Error: ${error}`;
	if (!data.me) return <Redirect to='/login' />;

	return (
		<div className='app'>
			<AppHeader fixed>
				<DefaultHeader user={data.me} />
			</AppHeader>
			<div className='app-body'>
				<AppSidebar fixed display='lg'>
					<AppSidebarHeader />
					<AppSidebarForm />
					<AppSidebarNav navConfig={getNavigationItems(data.me.type)} {...props} />
					<AppSidebarFooter />
					{/* <AppSidebarMinimizer /> */}
				</AppSidebar>
				<main className='main'>
					<AppBreadcrumb appRoutes={routes} />
					<Container fluid>
						<Switch>
							{routes.map((route, idx) =>
								route.component ? (
									<Route
										key={idx}
										path={route.path}
										exact={route.exact}
										name={route.name}
										render={props => (
											<div className='animated fadeIn'>
												<route.component {...props} />
											</div>
										)}
									/>
								) : null,
							)}
							<Redirect from='/' to='/esims/search' />
						</Switch>
					</Container>
				</main>
			</div>
		</div>
	);
};

export default DefaultLayout;

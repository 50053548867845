export default {
	items: [
		{
			name: 'Inicio',
			url: '/dashboard',
			icon: 'icon-home',
		},
		{
			divider: true,
		},
		{
			title: true,
			name: 'Esims',
			wrapper: {
				element: '',
				attributes: {},
			},
			class: '',
		},
		{
			name: 'Buscar',
			url: '/esims/search',
			icon: 'fa fa-search',
		},
		{
			name: 'Bolsas',
			url: '/esims/bags',
			icon: 'fa fa-shopping-cart',
			visibleBy: ['admin'],
		},
		{
			name: 'Cargar Bolsa',
			url: '/esims/bags/new',
			icon: 'fa fa-upload',
			visibleBy: ['admin'],
		},
		{
			name: 'Stock',
			url: '/esims/stock',
			icon: 'fa fa-money',
			visibleBy: ['admin'],
		},
		{
			name: 'Reenviar eSIM',
			url: '/esims/send',
			icon: 'fa fa-envelope-o',
		},
		{
			name: 'Recuperar eSIM',
			url: '/esims/recover',
			icon: 'fa fa-undo',
		},
		{
			divider: true,
			visibleBy: ['none'],
		},
		{
			title: true,
			name: 'Productos',
			wrapper: {
				element: '',
				attributes: {},
			},
			class: '',
			visibleBy: ['none'],
		},
		{
			name: 'Todos los Productos',
			url: '/productos',
			icon: 'icon-drawer',
			visibleBy: ['none'],
		},
		{
			title: true,
			name: 'Logs',
			wrapper: {
				element: '',
				attributes: {},
			},
			class: '',
			visibleBy: ['admin'],
		},
		{
			name: 'Revisar Logs',
			url: '/logs',
			icon: 'fa fa-pencil',
		},
	],
};

import React from 'react';

const Loading = ({ text = 'Cargando Módulo', ...props }) => (
	<div className='d-flex justify-content-center p-5'>
		<div className='align-self-center p-5 m-auto text-center'>
			<i className='fa fa-cog fa-spin fa-2x fa-fw' />
			<p>{text}</p>
			{process.env.NODE_ENV !== 'production' && <pre>{JSON.stringify(props, true, 2)}</pre>}
		</div>
	</div>
);

export default Loading;

import React, { Component } from 'react';
import { Nav } from 'reactstrap';
import PropTypes from 'prop-types';
import Gravatar from 'react-gravatar';

import { /* AppAsideToggler, */ AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
// import logo from '../../assets/img/brand/logo.svg';
import sygnet from '../../assets/img/brand/sygnet.svg';

const logo =
	'https://cdn.shopify.com/s/files/1/2347/1879/files/Logo_color_corporativo_1c0e776e-b544-48f9-80ee-e995f44989d1_150x.png?v=1513409494';

const propTypes = {
	children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {
	render() {
		// eslint-disable-next-line
		const { children } = this.props;

		return (
			<React.Fragment>
				<AppSidebarToggler className='d-lg-none' display='md' mobile />
				<AppNavbarBrand
					full={{ src: logo, width: 89, height: 'auto', alt: 'Holafly' }}
					minimized={{ src: sygnet, width: 30, height: 30, alt: 'Holafly' }}
				/>
				<AppSidebarToggler className='d-md-down-none' display='lg' />

				{/*
				<Nav className='d-md-down-none' navbar>
					<NavItem className='px-3'>
						<NavLink href='/'>Dashboard</NavLink>
					</NavItem>
					<NavItem className='px-3'>
						<NavLink href='#/users'>Users</NavLink>
					</NavItem>
					<NavItem className='px-3'>
						<NavLink href='#'>Settings</NavLink>
					</NavItem>
				</Nav>
				{/*  */}
				<Nav className='ml-auto' navbar>
					{/*
					<NavItem className='d-md-down-none'>
						<NavLink href='#'>
							<i className='icon-bell' />
							<Badge pill color='danger'>
								5
							</Badge>
						</NavLink>
					</NavItem>
					<NavItem className='d-md-down-none'>
						<NavLink href='#'>
							<i className='icon-list' />
						</NavLink>
					</NavItem>
					<NavItem className='d-md-down-none'>
						<NavLink href='#'>
							<i className='icon-location-pin' />
						</NavLink>
					</NavItem>
					*/}
					<AppHeaderDropdown direction='down'>
						{/* <DropdownToggle nav> */}
						<Gravatar email={this.props.user.email} className='img-avatar' size={35} default='mp' />
						{/* </DropdownToggle> */}
						{/*
						<DropdownMenu right style={{ right: 'auto' }}>
							<DropdownItem header tag='div' className='text-center'>
								<strong>Account</strong>
							</DropdownItem>
							<DropdownItem>
								<i className='fa fa-bell-o' /> Updates
								<Badge color='info'>42</Badge>
							</DropdownItem>
							<DropdownItem>
								<i className='fa fa-envelope-o' /> Messages
								<Badge color='success'>42</Badge>
							</DropdownItem>
							<DropdownItem>
								<i className='fa fa-tasks' /> Tasks
								<Badge color='danger'>42</Badge>
							</DropdownItem>
							<DropdownItem>
								<i className='fa fa-comments' /> Comments
								<Badge color='warning'>42</Badge>
							</DropdownItem>
							<DropdownItem header tag='div' className='text-center'>
								<strong>Settings</strong>
							</DropdownItem>
							<DropdownItem>
								<i className='fa fa-user' /> Profile
							</DropdownItem>
							<DropdownItem>
								<i className='fa fa-wrench' /> Settings
							</DropdownItem>
							<DropdownItem>
								<i className='fa fa-usd' /> Payments
								<Badge color='secondary'>42</Badge>
							</DropdownItem>
							<DropdownItem>
								<i className='fa fa-file' /> Projects
								<Badge color='primary'>42</Badge>
							</DropdownItem>
							<DropdownItem divider />
							<DropdownItem>
								<i className='fa fa-shield' /> Lock Account
							</DropdownItem>
							<DropdownItem>
								<i className='fa fa-lock' /> Logout
							</DropdownItem>
						</DropdownMenu>
							*/}
					</AppHeaderDropdown>
				</Nav>
				{/* <AppAsideToggler className="d-md-down-none" /> */}
				{/* <AppAsideToggler className="d-lg-none" mobile /> */}
			</React.Fragment>
		);
	}
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;

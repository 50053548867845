import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
// import GoogleLogin from 'react-google-login';
import { useQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { GoogleLogin } from '@react-oauth/google';


import { Card, CardBody, CardGroup, Col, Container, Row } from 'reactstrap';

import LayoutHeader from '../../components/LayoutHeader';
import Loading from '../../components/Loading';

const ME_QUERY = gql`
	query UserMe {
		me {
			username
			email
			picture
			type
		}
	}
`;

const LoginMutation = gql`
	mutation Login($input: UserSigninInput!) {
		signin(input: $input) {
			username
			email
			picture
			type
		}
	}
`;

const Login = () => {
	const [error, setError] = useState();

	const { loading, data } = useQuery(ME_QUERY, { errorPolicy: 'all' });
	const [doLogin] = useMutation(LoginMutation, {
		
		update(cache, { data: { signin: me } }) {
			cache.writeQuery({
				query: ME_QUERY,
				data: { me },
			});
		},
	});

	const handleLoginSuccess = async (response) => {
		setError();
		await doLogin({
			variables: {
				input: { token: response.credential },
			},
		}).catch(e => {
			setError({
				message: e.message.replace('GraphQL error: ', ''),
			});
		});
		
	};

	const handleLoginError = error => {
		setError(error.toString());
	};

	if (loading) return <Loading text='Cargando Login' />;

	if (data && data.me) return <Redirect to='/' />;

	return (
		<div className='app flex-row align-items-center'>
			<Container>
				<Row className='justify-content-center'>
					<Col xs='12' md='4'>
						<CardGroup>
							<Card className='p-4'>
								<CardBody>
									<LayoutHeader>Login</LayoutHeader>
									<p className='text-muted'>Accede a tu cuenta</p>
									{error && error.message && (
										<div className='alert alert-danger'>{error.message}</div>
									)}
									<GoogleLogin
										onSuccess={credentialResponse => {
											handleLoginSuccess(credentialResponse)
										}}
										onError={() => {
											console.log('Login Failed');
											handleLoginError()
										}}
									/>
									{/* <GoogleLogin
										clientId='850125748084-r6em6ce7nm66hse3r4jbgqvc97its7fc.apps.googleusercontent.com'
										onSuccess={handleLoginSuccess}
										onFailure={handleLoginError}
										cookiePolicy={'single_host_origin'}
									/> */}
								</CardBody>
							</Card>
						</CardGroup>
					</Col>
				</Row>
			</Container>
		</div>
	);
};
export default Login;

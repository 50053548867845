import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';

import { DefaultLayout } from './containers';
import Login from './views/Pages/Login';
import { GoogleOAuthProvider } from '@react-oauth/google';


import './scss/style.scss';
import '@coreui/icons/css/free.min.css';
import 'flag-icon-css/css/flag-icon.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'simple-line-icons/css/simple-line-icons.css';

const client = new ApolloClient({
	link: new HttpLink({
		uri: process.env.REACT_APP_API_URL,
		credentials: 'include',
	}),
	cache: new InMemoryCache(),
	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'network-only',
			errorPolicy: 'all',
		},
	},
});

const App = () => (
	<GoogleOAuthProvider clientId="850125748084-r6em6ce7nm66hse3r4jbgqvc97its7fc.apps.googleusercontent.com">
		<ApolloProvider client={client}>
			<Router>
				<Switch>
					<Route exact path='/login' name='Login Page' component={Login} />
					<Route path='/' name='Home' component={DefaultLayout} />
				</Switch>
			</Router>
		</ApolloProvider>
	</GoogleOAuthProvider>
);

export default App;
